import React from 'react'
import { Helmet } from 'react-helmet'

const HeroSection = () => {
  return (
    <div className='flex flex-col justify-center items-center'>
      <Helmet>
        <title>Speedwins</title>
        <meta name="description" content="We're
            dedicated to revolutionizing the human experience through disruptive
            technology. We are the parent company for a number of startup tech
            products. From research and development to market, we're committed
            to driving innovation that improves the way we live, work, and
            interact in the digital age." />
        {/* Add more meta tags as needed */}
      </Helmet>
      <img
        src='/images/heroBg.png'
        style={{ height: 'calc(100vh - 250px)' }}
        className='w-[100vw] object-cover'
      />

      <div className='absolute lg:flex top-[300px] sm:top-auto sm:bottom-[79px] gap-[20px] mx-[20px] justify-center items-center '>
        <div className='flex mb-5 sm:min-h-[250px] sm:mb-5 lg:mb-0 sm:max-w-[1017px] max-w-[329px] rounded-[20px]  m-auto px-[56px] py-[48px] flex-col justify-center items-start gap-[24px] bg-[#FFF] z-50'>
          <div className='text-black font-kalam text-[36px] sm:text-[42px] flex leading-[2px] sm:leading-[45px] justify-start'>
            About us
          </div>
          <div className='text-[#5E5E5E] sm:text-[20px] text-[20px] lg:text-[24px]  font-normal font-kalam'>
            Welcome to <strong>Speedwins Incubation Lab</strong>. We're
            dedicated to revolutionizing the human experience through disruptive
            technology. We are the parent company for a number of startup tech
            products. From research and development to market, we're committed
            to driving innovation that improves the way we live, work, and
            interact in the digital age.
          </div>
        </div>
        <div className='flex sm:max-w-none lg:max-w-[335px] sm:w-full  sm:min-h-[350px] lg:min-h-[350px] xl:min-h-[300px] sm:mx-auto  mb-5 sm:mb-0 md:w-full max-w-[329px] rounded-[20px]  px-[56px] py-[48px] flex-col justify-start items-start sm:gap-[24px] gap-[20px] bg-[#FFF] z-50'>
          <div className='text-black font-kalam sm:text-[24px] lg:text-[42px] text-[36px] flex justify-start'>
            Our Portfolio
          </div>
          <ul class='list-disc list-inside text-[#5E5E5E] text-[24px] font-normal font-kalam'>
            <li>
            Budget App
            </li>
          </ul>
          {/* <div className='text-[#5E5E5E] text-[24px] font-bold font-kalam'>
            Budget App
          </div> */}
        </div>
      </div>
      <div className='fixed flex justify-center bottom-0 sm:blackfooter items-center bg-[black] sm:h-[50vh] h-[100vh] w-[100vw] -z-20' />
    </div>
  )
}

export default HeroSection
