import logo from './logo.svg';
import './App.css';
import { Button } from '@mui/material';
import Homepage from './pages/Homepage';

function App() {
  return (
    <div className="flex justify-center items-start  ">
    <Homepage/>

  </div>
  );
}

export default App;
