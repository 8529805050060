import React from 'react'
import Header from '../components/other/Header.js'
import HeroSection from '../components/other/HeroSection.js'
import { Helmet } from 'react-helmet'

const Homepage = () => {
  return (
    <div >
       <Helmet>
        <title>Speedwins</title>
        <meta name="description" content="SPEEDWINS SURAT" />
        {/* Add more meta tags as needed */}
      </Helmet>
      <Header/><HeroSection/></div>
  )
}

export default Homepage