import React from "react";
import { Helmet } from "react-helmet";

function Header() {
  return (
    <div className="flex">
       <Helmet> 
        <title>Speedwins</title>
        <meta name="description" content="Your meta description here" />
        <meta
      property="og:title"
      content="Speedwins - Product Development Company | Surat, Gujarat, India"
    />
    <meta
      property="og:description"
      content="Speedwins is a product development company based in Surat, Gujarat, India. We specialize in creating innovative solutions to meet your business needs. Contact us today for custom development services."
    />
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://www.speedwins.in" />
    <meta
      property="og:image"
      content="%PUBLIC_URL%/fevicon_speedwins 24x24.png"
    />
    <meta property="og:site_name" content="Speedwins" />
        {/* Add more meta tags as needed */}
      </Helmet>
      <div className="sm:flex flex-shrink-0 shadow-lg justify-between items-center sm:h-[80px] h-[110px] w-[100vw] px-4 sm:px-8 py-2 sm:py-4 bg-gray-300">
        <div className="flex sm:font-bold font-[400] px-2 sm:px-4 sm:py-4 py-2 justify-center items-center">
          <div className="text-black text-[24px] sm:text-[30px] font-normal font-kalam">
            Speedwins Incubation Lab
          </div>
        </div>
        <div className=" flex justify-center  items-start">
        <a href="mailto:info@speedwins.in" className="flex py-[2px] text-[#14141580] text-[20px] sm:py-0 mx-4 font-kalam border-b-2 border-[#14141580]">
            info@speedwins.in
          </a>

        </div>
      </div>
    </div>
  );
}

export default Header;
